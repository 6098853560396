import Item from "./Item";
import logo from "../../images/logo.png";
// import { PRODUCTS, RESOURCES, COMPANY, SUPPORT } from "./Menu.js";

const ItemsContainer = () => {
  return (
    <div
      className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10
    text-center pt-2 text-gray-400 text-sm pb-8"
    >
     
      <div className="w-[300px] p-5">
        <img src={logo} alt="" />
      </div>

      <div>
      <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12152.653063308284!2d49.8213616!3d40.4052338!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4030878fd0819853%3A0xaecf8652ea14ffaa!2sDarkside%20Lounge!5e0!3m2!1str!2saz!4v1689333381723!5m2!1str!2saz"
          width="100%"
          className="p-5"
          height="300"
        ></iframe>
      </div>

      {/* <div>saat</div> */}
    </div>
  );
};

export default ItemsContainer;
