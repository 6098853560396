import React from 'react'
import Hero from '../components/Hero'
import HeadlineCards from '../components/HeadlineCards'
import Food from '../components/Food'
import Category from '../components/Category'
import Carousel from '../Carousel'


const Home = () => {
  return (
    <div>
        
        <Hero />
        <HeadlineCards />
        <Food />
        <Category />
        <Carousel/>
        
    </div>
  )
}

export default Home
