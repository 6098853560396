import React from 'react'

const Order = () => {
  return (
    <div>
      DHFHSFHSFH
    </div>
  )
}

export default Order
