import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header";
const RootLayout = () => {
  return (
    <>
      <Header/>
      <Navbar />
      <Outlet />
      <Footer/>
    </>
  );
};

export default RootLayout;
