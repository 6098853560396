import React from "react";
import ItemsContainer from "./ItemsContainer";
import Sociallicons from "./Sociallicons";


const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white">
      <div className="md:flex md:justify-between md:items-center sm:px-12 px-4 bg-[#ffffff19] py-7">
        <h1
          className="lg:text-4xl text-3xl md:mb-0 mb-6 lg:leading-normal font-semibold
         md:w-2/5"
        >
          <span className="text-[#c91414]">Bizimlə</span> əlaqə
          
        </h1>
        <div >
          <input
            type="text"
            placeholder="Telefon nömrənizi daxil edin"
            className="text-gray-800
           sm:w-72 w-full sm:mr-5 mr-1 lg:mb-0 mb-4 py-2.5 rounded px-2 focus:outline-none"
          />
          <button
            className="bg-[#c91414] hover:bg-[#c91414] border-[#c91414] duration-300 px-5 py-2.5 font-[Poppins]
           rounded-md text-white md:w-auto w-full"
          >
           Kodu tələb edin
          </button>
        </div>
      </div>
      <ItemsContainer />
      <div
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10
      text-center pt-2 text-gray-400 text-sm pb-8"
      >
        <span>© 2023 DSR. Hüquqlar qorunur.</span>
        <span>Şərtlər · Məxfilik Siyasəti</span>
        <Sociallicons    />
    
      </div>
    </footer>
  );
};

export default Footer;