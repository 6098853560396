import React from "react";
import { BsFacebook } from "react-icons/bs";
import {
  AiFillTwitterCircle,
  AiFillGithub,
  AiFillInstagram,
  AiOutlineYoutube,
} from "react-icons/ai";

const Sociallicons = () => {
  return (
    <div className="text-[#fff]">
      <span
        className="p-2 gap-3 cursor-pointer inline-flex items-center
        rounded-full bg-gray-700 mx-1.5 text-xl hover:text-gray-100 hover:bg-[#c91414]
        duration-300 "
      >
        <AiFillTwitterCircle />
        <BsFacebook />
        <AiFillGithub />
        <AiFillInstagram />
        <AiOutlineYoutube />
      </span>
    </div>
  );
};

export default Sociallicons;
