import React,  { useState } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Reservation from "./pages/Reservation";
import Order from "./pages/Order";
import Menu from "./pages/Menu";
import RootLayout from "./layouts/RootLayout";
import Home from "./pages/Home";




const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route path="reservation" element={<Reservation />} />
      <Route path="orders" element={<Order />} />
      <Route path="menu" element={<Menu />} />
    </Route>
  )
);

function App() {
 
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
