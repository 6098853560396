import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import menu1 from "./images/menu1.png";
import menu2 from "./images/menu2.png";
import menu3 from "./images/menu3.png";
import menu4 from "./images/menu4.png";
import menu5 from "./images/menu5.png";
import menu6 from "./images/menu6.png";

function Carousel() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    cssEase: "linear",
  };
  return (
    <div className="max-w-[1640px] m-auto px-4 ">
      <Slider {...settings}>
        <div className="card gap-3 ">
          <img src={menu1} alt="" className="w-[350px] rounded-br-3xl rounded-tl-3xl" />
          <div className="card-body">
            <h3></h3>
          </div>
        </div>
        <div className="card gap-3">
          <img src={menu2} alt="" className="w-[350px]" />
          <div className="card-body">
            <h3></h3>
          </div>
        </div>
        <div className="card gap-3">
          <img src={menu3} alt="" className="w-[350px]" />
          <div className="card-body">
            <h3></h3>
          </div>
        </div>
        <div className="card gap-3">
          <img src={menu4} alt="" className="w-[350px]" />
          <div className="card-body">
            <h3></h3>
          </div>
        </div>
        <div className="card gap-3">
          <img src={menu5} alt="" className="w-[350px]" />
          <div className="card-body">
            <h3></h3>
          </div>
        </div>
        <div className="card gap-3">
          <img src={menu6} alt="" className="w-[350px]" />
          <div className="card-body">
            <h3></h3>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default Carousel;
