import React from "react";
import menu1 from "../images/menu1.png";
import menu2 from "../images/menu2.png";
import menu3 from "../images/menu3.png";
import menu4 from "../images/menu4.png";
import menu5 from "../images/menu5.png";
import menu6 from "../images/menu6.png";
import menu7 from "../images/menu7.png";

const Menu = () => {
  return (
    <div className="max-w-[85%] mx-auto mt-14 ">
      <div className="text-[#c91414] font-bold  text-2xl ">
        <h3 className="font-bold text-2xl mt-8 ">Food Menu</h3>
        <h3 className="text-[#000]">Drink Menu</h3>
      </div>
      <div className="text-[#000] font-bold  text-2xl mt-14">
        <h3>Sendviçlər</h3>
      </div>
      <div className="grid grid-cols-4 mt-8 gap-5 hover14  ">
        <div className="menus_image ">
          <figure><img src={menu1} alt="" /></figure>
          <h3 className="font-bold text-lg mt-8 ">KLUB SENDVİÇ</h3>
          <span className="text-[#1A9DE7] font-bold text-base">8.50</span>
        </div>
        <div className="menus_image ">
         <figure> <img src={menu2} alt="" /></figure>
          <h3 className="font-bold text-lg mt-8 ">TOYUQ SENDVİÇ</h3>
          <span className="text-[#1A9DE7] font-bold text-base">8.50</span>
        </div>
        <div className="menus_image">
          <figure><img src={menu3} alt="" /></figure>
          <h3 className="font-bold text-lg mt-8 ">QARIŞIQ TOST</h3>
          <span className="text-[#1A9DE7] font-bold text-base">8.50</span>
        </div>
        <div className="menus_image">
          <figure><img src={menu4} alt="" /></figure>
          <h3 className="font-bold text-lg mt-8 ">KAŞAR TOST</h3>
          <span className="text-[#1A9DE7] font-bold text-base">8.50</span>
        </div>
      </div>
      <div className="flex gap-5 mt-20 ">
        <h3 className="font-bold text-2xl mt-8 text-[#c91414] ">Pastalar</h3>
      </div>
      <div className="grid grid-cols-4 mt-12  gap-5 hover14">
        <div className="menus_image">
          <figure><img src={menu5} alt="" /></figure>
          <h3 className="font-bold text-lg mt-8 ">KLUB SENDVİÇ</h3>
          <span className="text-[#1A9DE7] font-bold text-base">8.50</span>
        </div>
        <div className="menus_image">
          <figure><img src={menu6} alt="" /></figure>
          <h3 className="font-bold text-lg mt-8 ">TOYUQ SENDVİÇ</h3>
          <span className="text-[#1A9DE7] font-bold text-base">8.50</span>
        </div>
        <div className="menus_image">
          <figure><img src={menu7} alt="" /></figure>
          <h3 className="font-bold text-lg mt-8 ">QARIŞIQ TOST</h3>
          <span className="text-[#1A9DE7] font-bold text-base">8.50</span>
        </div>
        <div className="menus_image">
          <figure><img src={menu7} alt="" /></figure>
          <h3 className="font-bold text-lg mt-8 ">QARIŞIQ TOST</h3>
          <span className="text-[#1A9DE7] font-bold text-base">8.50</span>
        </div>
      </div>
    </div>
  );
};

export default Menu;
