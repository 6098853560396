import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../index.css";
import user from "../images/user.svg";
import {
  AiOutlineMenu,
  AiOutlineSearch,
  AiOutlineClose,
  AiFillTag,
  AiFillHome,
  AiOutlineUser,
} from "react-icons/ai";
import { BsFillCartFill, BsFillSaveFill } from "react-icons/bs";
import { TbTruckDelivery } from "react-icons/tb";
import { FaUserFriends } from "react-icons/fa";
import { MdFavorite, MdHelp, MdMenuBook } from "react-icons/md";
import { RiReservedLine } from "react-icons/ri";
import { BiUserPlus } from "react-icons/bi";
import Header from "./Header";
import Modal from "./Modal";
import SignUpModal from "./SignUpModal";


const Navbar = () => {
  const [nav, setNav] = useState(false);

  /* Modal Login forum */

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [isSignUpOpen, setSignUpOpen] = useState(false);
  const openModal1 = () => {
    setSignUpOpen(true);
  };
  const closeModal1 = () => {
    setSignUpOpen(false);
  };

  return (
    <div className="max-w-[1610px] mx-auto flex justify-between items-center p-4 bg-[#0e0d0b] ">
      {/* Left side */}

      <div className="flex items-center">
        <div onClick={() => setNav(!nav)} className="cursor-pointer">
          <AiOutlineMenu style={{ color: "#fff" }} size={30} />
        </div>
        <h1
          style={{ color: "#fff" }}
          className="text-2xl sm:text-3xl lg:text-4xl px-2"
        >
          Dark <span className="font-bold">Side</span>
        </h1>
      </div>

      {/* Search Input */}
      <div className="bg-gray-200 rounded-full flex items-center px-2 w-[200px] sm:w-[400px] lg:w-[500px]">
        <AiOutlineSearch size={25} />
        <input
          className="bg-transparent p-2 w-full focus:outline-none"
          type="text"
          placeholder="Axtar"
        />
      </div>

      <div className="flex items-center gap-4 ">
        <button
          onClick={openModal}
          className="bg-[#c91414] border-[#c91414] text-white hidden md:flex items-center py-2 rounded-full"
        >
          <AiOutlineUser size={20} className="mr-2" />Daxil ol
        </button>
        {isModalOpen && <Modal onClose={closeModal} />}
        <button
          onClick={openModal1}
          className="bg-[#c91414] border-[#c91414] text-white hidden md:flex items-center py-2 rounded-full"
        >
          <BiUserPlus size={25} className="mr-2" />Abunə ol
        </button>
        {isSignUpOpen && <SignUpModal onClose={closeModal1} />}

        {/* Cart button */}
        {/* <Link to="/card">
          <button className="bg-[#c91414] border-[#c91414] text-white hidden md:flex items-center py-2 rounded-full">
            <BsFillCartFill size={20} className="mr-2" />Səbət
          </button>
        </Link> */}
      </div>

      {/* Mobile Menu */}
      {/* Overlay */}
      {nav ? (
        <div className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0"></div>
      ) : (
        ""
      )}

      {/* Side drawer menu */}
      <div
        className={
          nav
            ? "fixed top-0 left-0 w-[300px] h-screen bg-white z-10 duration-300"
            : "fixed top-0 left-[-100%] w-[300px] h-screen bg-white z-10 duration-300"
        }
      >
        <AiOutlineClose
          onClick={() => setNav(!nav)}
          size={30}
          className="absolute right-4 top-4 cursor-pointer"
        />
        <h2 className="text-2xl p-4 text">
          Dark <span className="font-bold">Side</span>
        </h2>
        <nav className="sidebar_menu">
          <ul className="flex flex-col p-4 text-gray-800 ">
            <Link to={"/"}>
              <li className="text-xl py-4 flex border-t-[1px]">
                <AiFillHome size={25} className="mr-4" /> Home
              </li>
            </Link>

            <Link to={"menu"}>
              <li className="text-xl py-4 flex border-t-[1px]">
                <MdMenuBook size={25} className="mr-4" /> Menu
              </li>
            </Link>

            <Link to={"orders"}>
              <li className="text-xl py-4 flex border-t-[1px]">
                <TbTruckDelivery size={25} className="mr-4" /> Orders
              </li>
            </Link>

            <Link to={"reservation"}>
              <li className="text-xl py-4 flex border-t-[1px]">
                <RiReservedLine size={25} className="mr-4" /> Reservation
              </li>
            </Link>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
