import React from "react";
import logo from "../images/logo.png";
import { TiLocationOutline } from "react-icons/ti";
import { BiTimeFive } from "react-icons/bi";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import DarkMode from "./DarkMode";

const Header = () => {
  return (
    <div>
      <header className=" mx-auto flex justify-between items-center p-4 bg-black arif">
        <div className="w-full">
          <div className="header-top ">
            <div className="auto-container">
              <div className="inner clearfix flex justify-center items-center">
                <div className="top-left clearfix">
                  <ul className="top-info clearfix">
                    <li>
                      <img className="w-[160px]" src={logo} alt="" />
                    </li>
                    <li className="text-2xl py-4 flex ">
                      <TiLocationOutline size={24} className="mr-2" /> 30
                      Abdulvahab Salam Zadeh, Baku
                    </li>
                    <li className="text-xl py-4 flex">
                      <BiTimeFive size={24} className="mr-2" /> İş saatları:
                      14:00 - 02:00
                    </li>
                  </ul>
                </div>
                <div className="top-right clearfix">
                  <ul className="top-info clearfix">
                    <li className="text-xl py-4 flex">
                      <BsTelephone size={24} className="mr-2" />
                      050-888-02-00
                    </li>
                    <li className="text-xl py-4 flex">
                      <AiOutlineMail size={24} className="mr-2" />
                      info@darkside.com
                    </li>
                  </ul>
                </div>
                <div className="top-center clearfix">
                  <ul className="top-info clearfix">
                    <li>
                      <DarkMode/> 
                    </li>
                  </ul>

                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
