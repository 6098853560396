import React, { useState } from "react";
import { data } from "../data/data.js";

const Food = () => {
  //   console.log(data);
  const [foods, setFoods] = useState(data);

  const filterType = (category) => {
    setFoods(
      data.filter((item) => {
        return item.category === category;
      })
    );
  };

  //   Filter by price
  const filterPrice = (price) => {
    setFoods(
      data.filter((item) => {
        return item.price === price;
      })
    );
  };

  return (
    <div className="max-w-[1640px] m-auto px-4 py-12">
      <h1 className="text-[#c91414] font-bold text-4xl text-center">
        Ən çox sifariş verilən menyular
      </h1>

      {/* Filter Row */}
      <div className="flex flex-col lg:flex-row justify-between">
        {/* Fliter Type */}
        <div>
          <p className="font-bold text-gray-700">Filtr növü</p>
          <div className="flex justfiy-between flex-wrap">
            <button
              onClick={() => setFoods(data)}
              className="m-1 border-[#c91414] text-[#c91414] hover:bg-[#c91414] hover:text-white"
            >
              Hamısı
            </button>
            <button
              onClick={() => filterType("burger")}
              className="m-1 border-[#c91414] text-[#c91414] hover:bg-[#c91414] hover:text-white"
            >
              Burgers
            </button>
            <button
              onClick={() => filterType("pizza")}
              className="m-1 border-[#c91414] text-[#c91414] hover:bg-[#c91414] hover:text-white"
            >
              Pizza
            </button>
            <button
              onClick={() => filterType("pasta")}
              className="m-1 border-[#c91414] text-[#c91414] hover:bg-[#c91414] hover:text-white"
            >
              Pasta
            </button>
            <button
              onClick={() => filterType("sendviç")}
              className="m-1 border-[#c91414] text-[#c91414] hover:bg-[#c91414] hover:text-white"
            >
              Sendviçlər
            </button>
          </div>
        </div>

        {/* Filter Price */}
        <div>
          <p className="font-bold text-gray-700">Filter Price</p>
          <div className="flex justify-between max-w-[390px] w-full">
            <button
              onClick={() => filterPrice("₼")}
              className="m-1 border-[#c91414] text-[#c91414] hover:bg-[#c91414] hover:text-white"
            >
              ₼
            </button>
            <button
              onClick={() => filterPrice("₼₼")}
              className="m-1 border-[#c91414] text-[#c91414] hover:bg-[#c91414] hover:text-white"
            >
              ₼₼
            </button>
            <button
              onClick={() => filterPrice("₼₼₼")}
              className="m-1 border-[#c91414] text-[#c91414] hover:bg-[#c91414] hover:text-white"
            >
              ₼₼₼
            </button>
            <button
              onClick={() => filterPrice("₼₼₼₼")}
              className="m-1 border-[#c91414] text-[#c91414] hover:bg-[#c91414] hover:text-white"
            >
              ₼₼₼₼
            </button>
          </div>
        </div>
      </div>

      {/* Display foods */}
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 pt-4">
        {foods.map((item, index) => (
          <div
            key={index}
            className="border shadow-lg rounded-lg hover:scale-105 duration-300"
          >
            <img
              src={item.image}
              alt={item.name}
              className="w-full h-[200px] object-cover rounded-t-lg"
            />
            <div className="flex justify-between px-2 py-4">
              <p className="font-bold">{item.name}</p>
              <p>
                <span className="bg-[#c91414] text-white p-1 rounded-full">
                  {item.price}
                </span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Food;
